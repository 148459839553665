// Definición de tipos de acción relacionados con el usuario

export const UPDATE_USER = "UPDATE_USER";
export const GET_USER = "GET_USER";
export const UPDATE_USER_SUCCESS = "  UPDATE_USER_SUCCESS";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const SET_ALL_USERS = "SET_ALL_USERS";

export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES";
export const GET_ADDRESS = "GET_ADDRESS";

export const CREATE_SEDE = "CREATE_SEDE";
export const GET_ALL_SEDES = "GET_ALL_SEDES";
export const UPDATE_SEDE = "UPDATE_SEDE";
export const GET_SEDE = "GET_SEDE";
export const SEARCH_SEDES = "SEARCH_SEDES";
export const DELETE_SEDE = "DELETE_SEDE";

export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const SET_ALL_CLIENTS = "SET_ALL_CLIENTS";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";

export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const SET_ALL_POSTS = "SET_ALL_POSTS";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const UPDATE_POST_STATE_SUCCESS = "UPDATE_POST_STATE_SUCCESS";

export const CREATE_MENU = "CREATE_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const GET_ALL_MENUS = "GET_ALL_MENUS";
export const DELETE_MENU = "DELETE_MENU";

// serviceActionTypes.js
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAILURE = "CREATE_SERVICE_FAILURE";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAILURE = "GET_SERVICE_FAILURE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILURE = "UPDATE_SERVICE_FAILURE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE";

export const CREATE_CATEGORY_SERVICE_SUCCESS =
  "CREATE_CATEGORY_SERVICE_SUCCESS";
export const SET_ALL_CATEGORIES_SERVICE = "SET_ALL_CATEGORIES_SERVICE";
export const GET_CATEGORY_SERVICE_SUCCESS = "GET_CATEGORY_SERVICE_SUCCESS";
export const UPDATE_CATEGORY_SERVICE_SUCCESS =
  "UPDATE_CATEGORY_SERVICE_SUCCESS";
export const DELETE_CATEGORY_SERVICE_SUCCESS =
  "DELETE_CATEGORY_SERVICE_SUCCESS";

export const CREATE_PROVIDER_SUCCESS = "CREATE_PROVIDER_SUCCESS";
export const SET_ALL_PROVIDERS_SERVICE = "SET_ALL_PROVIDERS_SERVICE";
export const GET_PROVIDER_SUCCESS = "GET_PROVIDER_SUCCESS";
export const UPDATE_PROVIDER_SUCCESS = "UPDATE_PROVIDER_SUCCESS";
export const DELETE_PROVIDER_SUCCESS = "DELETE_PROVIDER_SUCCESS";

export const CREATE_ALLY_SUCCESS = "CREATE_ALLY_SUCCESS";
export const SET_ALL_ALLIES_SERVICE = "SET_ALL_ALLIES_SERVICE";
export const GET_ALLY_SUCCESS = "GET_ALLY_SUCCESS";
export const UPDATE_ALLY_SUCCESS = "UPDATE_ALLY_SUCCESS";
export const DELETE_ALLY_SUCCESS = "DELETE_ALLY_SUCCESS";

export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const SET_ALL_PROJECTS_SERVICE = "SET_ALL_PROJECTS_SERVICE";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";

export const CREATE_FOUNDATION_SUCCESS = "CREATE_FOUNDATION_SUCCESS";
export const SET_ALL_FOUNDATIONS_SERVICE = "SET_ALL_FOUNDATIONS_SERVICE";
export const DELETE_FOUNDATION_SUCCESS = "DELETE_FOUNDATION_SUCCESS";
export const GET_FOUNDATION_BY_ID_SUCCESS = "GET_FOUNDATION_BY_ID_SUCCESS";

export const CREATE_CERTIFICATION_SUCCESS = "CREATE_CERTIFICATION_SUCCESS";
export const SET_ALL_CERTIFICATIONS_SERVICE = "SET_ALL_CERTIFICATIONS_SERVICE";
export const GET_CERTIFICATION_SUCCESS = "GET_CERTIFICATION_SUCCESS";
export const UPDATE_CERTIFICATION_SUCCESS = "UPDATE_CERTIFICATION_SUCCESS";
export const DELETE_CERTIFICATION_SUCCESS = "DELETE_CERTIFICATION_SUCCESS";

export const CREATE_MAKINA_ANDINA_SUCCESS = "CREATE_MAKINA_ANDINA_SUCCESS";
export const CREATE_MAKINA_ANDINA_FAILURE = "CREATE_MAKINA_ANDINA_FAILURE";
export const DELETE_MAKINA_ANDINA_SUCCESS = "DELETE_MAKINA_ANDINA_SUCCESS";
export const DELETE_MAKINA_ANDINA_FAILURE = "DELETE_MAKINA_ANDINA_FAILURE";
export const GET_MAKINA_ANDINA_SUCCESS = "GET_MAKINA_ANDINA_SUCCESS";
export const GET_MAKINA_ANDINA_FAILURE = "GET_MAKINA_ANDINA_FAILURE";
export const GET_SERVICE_MAKINA_ANDINA_SUCCESS =
  "GET_SERVICE_MAKINA_ANDINA_SUCCESS";
export const GET_SERVICE_MAKINA_ANDINA_FAILURE =
  "GET_SERVICE_MAKINA_ANDINA_FAILURE";

export const CREATE_MAKINA_ANDINA_MIAMI_SUCCESS = "CREATE_MAKINA_ANDINA_MIAMI_SUCCESS"
export const CREATE_MAKINA_ANDINA_MIAMI_FAILURE = "CREATE_MAKINA_ANDINA_MIAMI_FAILURE"
export const DELETE_MAKINA_ANDINA_MIAMI_SUCCESS = "DELETE_MAKINA_ANDINA_MIAMI_SUCCESS"
export const DELETE_MAKINA_ANDINA_MIAMI_FAILURE = "DELETE_MAKINA_ANDINA_MIAMI_FAILURE"
export const GET_MAKINA_ANDINA_MIAMI_SUCCESS = "GET_MAKINA_ANDINA_MIAMI_SUCCESS"
export const GET_MAKINA_ANDINA_MIAMI_FAILURE = "GET_MAKINA_ANDINA_MIAMI_FAILURE"
export const GET_SERVICE_MAKINA_ANDINA_MIAMI_SUCCESS = "GET_SERVICE_MAKINA_ANDINA_MIAMI_SUCCESS"
export const GET_SERVICE_MAKINA_ANDINA_MIAMI_FAILURE = "GET_SERVICE_MAKINA_ANDINA_MIAMI_FAILURE"


export const CREATE_GLAMPING_SERVICE_SUCCESS = "CREATE_GLAMPING_SERVICE_SUCCESS";
export const SET_ALL_GLAMPING_SERVICES_SERVICE = "SET_ALL_GLAMPING_SERVICES_SERVICE";
export const GET_GLAMPING_SERVICE_BY_ID_SUCCESS = "GET_GLAMPING_SERVICE_BY_ID_SUCCESS";
export const DELETE_GLAMPING_SERVICE_SUCCESS = "DELETE_GLAMPING_SERVICE_SUCCESS";


export const CREATE_TESTIMONIE_SUCCESS = "CREATE_TESTIMONIE_SUCCESS";
export const SET_TESTIMONY = "SET_TESTIMONY";
export const SET_ALL_TESTIMONIES = "SET_ALL_TESTIMONIES";
export const UPDATE_TESTIMONIE_SUCCESS = "UPDATE_TESTIMONIE_SUCCESS";
export const DELETE_TESTIMONIE_SUCCESS = "DELETE_TESTIMONIE_SUCCESS";

export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";
export const GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS";
export const DELETE_DEPARTMENTS_SUCCESS = "DELETE_DEPARTMENTS_SUCCESS";
export const DELETE_DEPARTMENTS_FAILURE = "DELETE_DEPARTMENTS_FAILURE";

export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const SET_ALL_PROFILES = "SET_ALL_PROFILES";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";