import React, { useState, useEffect } from "react";

import { Suppliers } from "../../../components/Admin/Suppliers";

export const Providers = () => {
  return (
    <div>
      <h2>Proveedores</h2>
      <Suppliers />
    </div>
  );
};
